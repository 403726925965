/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  --font-family: 'Montserrat';
  --heading-color: #242f39;
  --bg-active: #f5be1d;
  --text-common-dark: #000;
  --back-odd-background: #95d2ff;
  --back-odd-background-light: #adcde5;
  --back-odd-background-rgb: 151, 211, 255;
  --lay-odd-background: #ffa0a0;
  --lay-odd-background-rgb: 255, 173, 173;
  --lay-odd-background-light: #e9b9c7;

  --premium-odd-background: #95d2ff;
  /*  #72e3a0 */
  --premium-odd-background-light: #adcde5;
  /*#80e6aa*/
  --premium-odd-background-rgb: 151, 211, 255;
  /* 14, 227, 160; */

  --ion-color-success: #34a300;
  --ion-toast--success: #34a300;
  /** danger **/
  --ion-color-danger: #ff6262;

  --odd-btn-animation-color: #ffa724;

  /** Header Logo Sizes **/
  --logo-xl-width: 150px;
  --logo-xl-height: 40px;
  --logo-lg-width: 116px;
  --logo-lg-height: 36px;
  --logo-md-width: 64px;
  --logo-md-height: 36px;
  --logo-sm-width: 94px;
  --logo-sm-height: 36px;
  --logo-xs-width: 74px;
  --logo-xs-height: 36px;

  --logo-casino-xl-width: 85px;

}

/*
 * Light Colors
 * ------------------
 */

.ios body,
.md body.light {
  --text: #000;
  --color-black: #000;
  --payment-background: transparent;
  --tab-color: #eee;
  --headerbg: #dfdfdf;
  --background-image-color: #fff;
  --ion-background: #dfdfdf;
  --ion-background-color: #dfdfdf;
  --scorecard-bg: #fff2cf;
  --scorecard-text: #000;
  --bg1: rgba(19, 97, 46, 0.1);
  --table-head-bg: #f5be1d;
  --table-head-text: #000;
  --footer-icon-bg: #fff2cf;
  --score-card-details-color: #fff;
  --accordian-headers-color: #000;
  --footer-bg: #0d411f;
  --signup-btn: #000;
  --sub-header-text: #1b1b1b;
  --active-link: #fffff;
  --tab-bg: #fff2cf;
  --opposite-color: #000;
  --text-primary: #fff;
  --table-even: #fff2cf;
  --input-background-color: #fffaec;
  --input-text-color: #000000;
  --date-selected-bg: #f5be1d;
  --date-selected-color: #000;
  --report-table-background: #fff5da;
  --report-table-text: #000;
  --filter-text: #000;
  --border-color: #f5be1d;
  --betslip-bg: #fff2cf;
  --button: #cf8d2e;
  --collapse: #fff2cf;
  --ion-popover-background: var(--ion-background);
  --ion-secondary: #242f39;
  --market-table-row: #f6f6f4;
  --scorcard-color: #000;
  --text-secondary: #000;
  --mobile-footer: #f5be1d;
  --footer-bg-active: #fff2cf;
  --live-tab-color: #fff;
  --shadow-color: 0, 0, 0;
  --footer-text-active: #000;
  --text-copyright: #000;
  --tab-text: #000;
  --icon: invert(0);
  --background-gradient: linear-gradient(
    90deg,
    #fff 0%,
    #cf8d2e 52.35%,
    #fff 100.9%
  );
  --card-background: #efefef;
  --sub-header-text: rgba(0, 0, 0, 0.5);
  --sub-header-btn-text: rgba(0, 0, 0);
  --active-sub-header-text: rgba(0, 0, 0);
  --header-btn-background: #fff;
  --sub-header-background: #ffffff;
  --sub-header-btn-background: #ffffff;
  --mob-header-background: linear-gradient(180deg, #ffffff 0%, #414242 100%);
  --theme-toggle-btn: linear-gradient(
    127.35deg,
    #000000 17.88%,
    #242f39 83.47%
  );
  --form-input-background: #fff;
  --card-border: 1px solid #c9c9c9;
  --background-image-opacity: 0.4;
}

/* .ios body.dark,
.md body.dark {
  --text: #fff;
  --scorecard-text: #fff;
  --scorecard-bg: #131313;
  --ion-background: #1e1e1e;
  --ion-secondary: #131313;
  --market-table-row: #131313;
  --ion-popover-background: var(--ion-background);
  --headerbg: #202020;
  --bg1: rgba(19, 19, 19, 0.8);
  --accordian-headers-color: #000;
  --footer-icon-bg: #1d1d2d;
  --footer-text: #322607;
  --footer-bg: #181818;
  --signup-btn: #1b1b1b;
  --sub-header-text: #f5be1d;
  --active-link: #f5be1d;
  --tab-bg: #1d1d2d;
  --opposite-color: #fff;
  --text-primary: #fff;
  --table-even: #121212;
  --input-background-color: #131313;
  --input-text-color: #bca13e;
  --date-selected-bg: #d9bb73;
  --date-selected-color: #000;
  --report-table-background: #000;
  --report-table-text: #bca13e;
  --filter-text: #bca13e;
  --filter-text: #bca13e;
  --scorcard-color: #fff;
  --border-color: #c7981d;
  --betslip-bg: #000;
  --button: #c7981d;
  --collapse: #000;
  --text-secondary: #c7981d;
  --mobile-footer: #000;
  --footer-bg-active: #c7981d;
  --live-tab-color: #000;
  --shadow-color: 255, 255, 255;
  --footer-text-active: #000;
  --text-copyright: #000;
  --tab-text: var(--heading-color);
} */

body.dark {
  --text: #fff;
  --tab-color: #fff;
  --payment-background: #fff;
  --headerbg: #000;
  --color-black: #000;
  --scorecard-text: #000;
  --background-image-color: #000;
  --scorecard-bg: #dededc;
  --ion-background: #000;
  --ion-background-color: #000;
  --ion-secondary: #000;
  --market-table-row: #f6f6f4;
  --ion-popover-background: var(--ion-background);
  --bg1: #dddcd6;
  --accordian-headers-color: #000;
  --footer-icon-bg: #1d1d2d;
  --footer-text: #322607;
  --footer-bg: #f7f7f7;
  --signup-btn: #ffffff;
  --active-link: #ffbf18;
  --tab-bg: #dededc;
  --opposite-color: #fff;
  --text-primary: #000;
  --table-even: #121212;
  --input-background-color: #ffffff;
  --input-text-color: #ffbf18;
  --date-selected-bg: #ffbf18;
  --date-selected-color: #000;
  --report-table-background: #000;
  --report-table-text: #ffbf18;
  --filter-text: #ffbf18;
  --filter-text: #ffbf18;
  --scorcard-color: #fff;
  --border-color: #ffbf18;
  --betslip-bg: #000;
  --button: #cf8d2e;
  --collapse: #000;
  --text-secondary: #ffbf18;
  --mobile-footer: #000;
  --footer-bg-active: #ffbf18;
  --live-tab-color: #000;
  --shadow-color: 255, 255, 255;
  --footer-text-active: #000;
  --text-copyright: #000;
  --tab-text: var(--heading-color);
  --icon: invert(1);
  --background-gradient: linear-gradient(
    90deg,
    #000000 0%,
    #cf8d2e 52.35%,
    #000000 100.9%
  );
  --background-image-color: #222222;
  --card-background: #373737;
  --sub-header-text: rgba(255, 255, 255, 0.5);
  --sub-header-btn-text: rgba(255, 255, 255, 0.5);
  --active-sub-header-text: rgba(255, 255, 255);
  --sub-header-background: var(--background-gradient);
  --header-btn-background: linear-gradient(180deg, #181f25 0%, #3b3c3c 100%);
  --sub-header-btn-background: #000;
  --mob-header-background: linear-gradient(180deg, #000000 0%, #414242 100%);
  --theme-toggle-btn: linear-gradient(
    127.35deg,
    #ffffff 17.88%,
    rgba(255, 255, 255, 0.53) 83.47%
  );
  --form-input-background: linear-gradient(
    180deg,
    #161d23 0%,
    rgba(65, 65, 65, 0.96) 100%
  );
  --card-border: none;
  --background-image-opacity: 0.8;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
