.promotion_page {
    padding: 14px 34px;
    background-color: var(--ion-background);
    color: #f8f8f8;
  
    .web-banner{
      margin-top: 10px;
      img{width: 100%;}
    }
    .MuiTabs-flexContainer,
    .types-tabs {
      display: flex;
      width: max-content;
      align-items: center;
      min-height: 50px;
      max-height: 50px;
      border-radius: 51px !important;
      background: var( --sub-header-btn-background);
      border: 1.03636px solid var(--button); 
      box-shadow: inset 0px 1.55455px 10.3636px rgba(0, 0, 0, 0.15);
      @media screen and (max-width: 720px) {
        justify-content: left !important;
        width: unset;
      }
      .MuiTab-root {
        &.arrow-tab {
          text-decoration: none;
          align-items: center;
          border-right: 1px solid var(--ion-color-primary);
          opacity: 1;
        }
        min-height: 50px;
        max-height: 50px;
        text-decoration: none;
        align-items: center;
        opacity: 1;
        border-radius: 51px;
        .MuiTab-wrapper {
          font-style: normal;
          font-size: 12px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: var(--sub-header-btn-text) !important;
          text-transform: uppercase;
          text-align: center;
        }
        &.Mui-selected {
          opacity: 1;
          border-radius: 51px;
          background: #cf8d2e !important;
          color: var(--active-sub-header-text);
          .MuiTab-wrapper {
            color: var(--active-text) !important;
  
            border-right: 0px !important;
            border-left: 0px !important;
          }
        }
      }
    }
    .card_promotion{
      width: 100%;
      background: var(--background-ctn-gradient);
      // opacity: 0.5;
      border-radius: 10px;
      margin: 24px 0;
      padding: 12px 16px 16px 16px;
    }
    .header-align{
      width: fit-content;
    }
    .header_title {
      display: flex;
      align-items: center;
      font-family: var(--headers-font-family);
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 38px;
      letter-spacing: 0.08em;
      color: #f8f8f8;
      
    }
    .text-border-btm {
      background: linear-gradient(90deg, var(--linear-color-change) 0%, rgba(255, 167, 36, 0) 100%);
      border-radius: 10px;
      height: 2px;
      margin-bottom: 20px;
    }
    .subheader_title{font-size: 28px;padding-bottom: 15px;}
  
    .paragrah_align{
      font-size: 16px;
      font-weight: 400;
      margin: 0px;
      padding-bottom: 15px;
      .step_align{
        font-weight: 600;
      }
    }
    .fbold{font-weight: bold;padding-bottom: 10px;}
    .magrain_align{
      margin-bottom: 5px;
    }
    .inside_card{
      width: 100%;
      background: 
      linear-gradient(var(--ion-background-tertiary) 0 0) padding-box, /*this is your grey background*/
      linear-gradient(180.28deg, var(--linear-color-change)  -8.4%, #A6A6A6) border-box;
      padding: 10px;
      border: 3.34px solid transparent;
      border-radius: 8px;
      display: inline-block;
    }
  
    .warning_text{
      font-size: 13px;
      color: red;
    }
    .terms_title{
      display: flex;
      align-items: center;
      font-family: var(--headers-font-family);
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 38px;
      letter-spacing: 0.08em;
      margin-bottom: 10px;
      color: #f8f8f8;
    }
  }
  
  @media (min-width: 601px) {
    .mob-banner {
      display: none !important;
    }
    .mob-content {
      display: none !important;
    }
  }
  @media (min-width: 120px) and (max-width: 600px) {
    .web-banner {
      display: none !important;
    }
    .web-content {
      display: none !important;
    }
    .mob-banner img{margin-top: 20px; width: 100%;}
    .promotion_page {padding: 14px 15px;
      .header_title{font-size: 18px;}
      .subheader_title {font-size: 16px}
      .warning_text {font-size: 11px;}
      .paragrah_align {font-size: 14px;}
      .terms_title{font-size: 16px;}
    }
  }