.payment-withdraw-ctn {
  margin: 16px 15px;

  .MuiButton-label, .MuiButton-label, .MuiButton-contained {
    background-color: transparent !important;
  }

  @media screen and (max-width: 720px) {
    margin: 10px 16px !important;
  }

  .withdraw-deposit-btn {
    color: black;
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px;
    background-color: var(--ion-color-primary);
  }
}
.account-details-ctn {
  display: flex;

  .delete-btn-ctn {
    .method-delete-btn {
      height: 100%;
      min-width: fit-content;
      background: #ddcece;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-right: 8px;
    }
  }

  .account-btn-ctn {
    display: flex;
  }
  .account-detail-withdraw {
    display: block;
    background-color: #eee;
    margin-right: 0;
    padding: 8px 15px;
    min-width: 180px;
    text-transform: capitalize;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &.active {
      background-color: var(--ion-color-secondary);
      color: #fff;
    }
  }
}

.account-inputs {
  .account-input {
    margin-top: 10px;

    .deposit-image {
      width: 100%;
    }
    .acc-input {
      border: 0;
      background-color: #eee;
      border-radius: 10px;
      padding: 14px;
      min-width: 100%;

      &.MuiFormControl-root {
        padding: 0;
        .MuiInputBase-root {
          &::before {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .upload-btn {
    background-color: var(--ion-color-secondary);
    color: #fff;
    text-transform: capitalize;
    margin-top: 8px;
  }

  .submit-payment-btn {
    margin-top: 16px;
    margin-bottom: 20px;
    background-color: var(--ion-color-primary);
    padding: 8px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px;

    min-width: 100%;
  }
}
.my-payment-option {
  margin-bottom: 20px;

  .deposit-form-ctn {
    .payment-options {
      // margin: 12px 0 20px 0;
      border-bottom: 1px solid #cdcdcd;

      &.online-payment-options {
        margin-top: 12px;
        .active {
          color: #000;
        }
      }
      .payment-btn {
        margin-right: 10px;
        padding: 5px 9px;
        min-height: 40px;
        min-width: 72px;
      }
      .active {
        // background-color: var(--ion-color-secondary);
        color: #fff;
      }
    }
  }
}
.payment-deposit-ctn {
  margin: 16px 15px;

  .payment-option-title,
  .account-details-title,
  .uploaded-image-title {
    display: inline-grid;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 6px;
  }

  .account-input-title {
    display: inline-grid;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
  }
  .payment-form-divider {
    height: 1px;
    background: rgb(212, 211, 211);
    margin: 26px 0 15px 0;
  }
}
.payment-option-title {
  display: inline-grid;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
  text-transform: capitalize;
  font-weight: bold;
}
.withdraw-form-divider {
  height: 1px;
  background: rgb(212, 211, 211);
  margin: 26px 0 15px 0;
}
.add-btn {
  background: green;
  margin-left: 4px;

  .add-account {
    background: white;
    margin-top: 3px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    height: 30px;
    width: 30px;
    border-radius: 100px;
    size: 20px;
  }
}

// .payment-icon{
//   height: 25px;
  
// }

.payment-method-confirm {
  .MuiPaper-root {
    background: #fff !important;
    padding: 16px 30px;

    @media screen and (max-width: 720px) {
      padding: 12px 24px;
      margin: 0 10px;
      min-width: unset;
    }

    .payment-text-bold {
      font-size: 20px;
      font-weight: bold;
    }

    .dialog-footer {
      padding-top: 20px;
    }
    .footer-action-btn {
      background-color: var(--ion-color-primary);
      color: #000;

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}