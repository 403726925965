.mlooby-ctn {
  display: flex;
    z-index: 3;
    width: 100%;
}

.mlobby-main{
  display: flex;
  position: fixed;
  transform: translate3d(0,0,0);
  left: 10px;
  bottom: 44px;
  z-index: 11111;
  cursor: pointer;
  width: 12%;
  --opacityValue: 0;
}

.mini-iframe-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  right: 10px;
  .MuiSvgIcon-root {
    font-size: 2em;
    position: relative;
    path {
    fill: #8f5f1b;
    }
  }
}

.mlobby-main-dc{
  display: flex;
  position: fixed;
  transform: translate3d(0,0,0);
  height: 265px;
  bottom: 50px;
  z-index: 11;
  cursor: pointer;
  width: 100%;
  --opacityValue:0;
  background: #fff;

  &.d-none {
    display: none;
  }

  
}

.mlobby-main-dc {
  border-radius: 10px 10px 0 0;
  background: linear-gradient(0deg, #000, #272727);
}

.mlobby-main-dc:before, .mlobby-main-dc:after {
  content: '';
  position: absolute;
  left: -3.5px;
  top: -5px;
  background: linear-gradient(45deg, #ffffff, #c1c1ec, #c3c8fc, #1e1c36, #000000, 
    #131329, #d3fcd3,#fcfcd3, #ffffff);
  background-size: 400%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
  animation: steam 20s linear infinite;
  border-radius: 10px 10px 0 0;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.mlobby-main-dc:after {
  filter: blur(10px);
}

.mini-casino-btn {
  // box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
  border-radius: 100px;
  left: 4px;
  bottom: 74px;
  &.d-none {
    display: none;
  }
  // &:before, &:after {
  //   content: '';
  //   position: absolute;
  //   left: -3.5px;
  //   top: -5px;
  //   background: linear-gradient(45deg,#fff,#c1c1ec,#c3c8fc,#df4f0d,#f38b03,#965705,#c52c05,#84012a,#fff);
  //   background-size: 400%;
  //   width: calc(100% + 7px);
  //   height: calc(100% - -3px);
  //   z-index: -1;
  //   animation: steam 8s linear infinite;
  //   border-radius: 100px;
  // }

  // .exch-nav-item-ctn {
  //   img {
  //     animation: flip 2s infinite linear;
  //   }
  // }

  .exch-nav-item-ctn {
    .mini-game-btn-bg {
      position: absolute;
      height: 48px;
      top: -2.4px;
      width: 48px;
    }
    .mini-games-gif {
      position: relative;
      height: 45px;
      width: 50px;
    }
  }

  @keyframes flip {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(180deg);
    }
  }
}

.dc-iframe-ctn-mlobby {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 8px 8px 0 0;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .dc-iframe-ctn-mlobby {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0 2px;
    }
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .dc-iframe-ctn-mlobby {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0 2px;

    }
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .dc-iframe-ctn-mlobby {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0 2px;
    }
  }
}
