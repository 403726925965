.login-form-ctn {
  padding-top: 15px;
  padding-bottom: 15px;

  .form-control {
    width: 100%;
    height: 46px;
    padding-left: 10px;
    font-family: var(--font-family);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }

/* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .MuiInputAdornment-positionEnd{
    margin-left: 0px;
  }



  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
}

  .eye-button{
    .MuiIconButton-root{
      position: absolute;
      right: 5px;
    }
  }

  // .MuiIconButton-root eye-button{
  //   position: absolute;
  //   right: 5px;
  // }

  .sign-in-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: var(--text);

    @media screen and (max-width: 720px) {
      font-size: 20px;
    }
  }

  // .MuiStepLabel-label {
  //   color: white !important;
  // }

  .MuiSvgIcon-root {
    color: #90949d;
    width: 2rem;
    margin-top: 10px;
    background: 0;
    cursor: pointer;
    &.no-margin {
      margin-top: 2px;
      color: var(--ion-color-dark);
    }  
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    color: #90949d;
    font-size: 15px;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #ffbf18;
  }

  .MuiTypography-body1 {
    padding-top: 16px;
  }

  .MuiPaper-root {
    border: none;
    background: 0;
}

  .MuiSvgIcon-root {
    color: grey;
    width: 2rem;
    margin-top: 10px;
    background: 0;
    cursor: pointer;
}

  .terms-conditions {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 5px;

    .terms-text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: var(--text);
      text-align: left;

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
    }
  }

  .login-col {
    width: 100%;
    margin-top: 5px;
    // margin-bottom: 5px;
  }

  .MuiOutlinedInput-root {
    background-color: var(--ion-background) !important;
    // border-bottom: 3px solid var(--headerbg) !important;
    border: 0px;
    color: var(--text) !important;
  }

  .MuiFormControl-root {
    display: flex;
    width: 100%;
  }

  .MuiInputBase-root .MuiFormControl-root,
  .MuiOutlinedInput-root,
  input {
    background: var(--form-input-background) !important;
    color: var(--text) !important;
    border-radius: 0px;
  }

  ::placeholder {
    font-size: 16px;
    color: var(--text) !important;
    padding: 2px;
    font-family: var(--font-family);
    opacity: 1;
  }

  .login-form-btn {
    margin-top: 1.5rem;
    height: 55px;
    width: 100%;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    background-color: var(--active-link);
    color: var(--text-common-dark) !important;
    border-radius: 6px;

    @media screen and (max-width: 720px) {
      height: 44px;
    }

    .MuiIconButton-label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #FFFFFF;

      @media screen and (max-width: 720px) {
        font-size: 18px !important;
      }

      svg {
        display: none;
        fill: var(--text-common-dark) !important;
        color: var(--text-common-dark) !important;
      }
    }

    .MuiButton-label {
      color: var(--text-copyright) !important;
    }
  }

  .label {
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 15px;
    &.link {
      font-size: 10px;
      text-decoration: underline;
      color: var(--ion-color-primary);
      float: right;
      margin-left: auto;
      padding-right: 5px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .MuiSvgIcon-root {
    path {
      fill: #ffbf18;
    }
  }

  .special-label{
    display: none;
  }

  .login-err-msg {
    color: var(--text-primary) !important;
  }
}